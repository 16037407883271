<template>
  <div class="person_infor">
    <div class="login_top">
      <!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
      <div class="login_title">{{ $t("other.myMessage") }}</div>
    </div>
    <!-- <div class="all_message">
			<div class="message_item" @click="toMessageDetail(item)" v-for="(item,index) in messageList" :key="index">
				<div class="message_item_top">
					<div class="message_item_left">
						<img :src="item.thumb">
					</div>
					<div class="message_item_right">
						<div class="message_name">{{item.name}}</div>
						<div class="message_time">{{item.new_msg.create_time}}</div>
					</div>
				</div>
				<div class="message_item_bottom">
					{{item.new_msg.title}}
				</div>
			</div>
		</div> -->

    <div class="all_message_box">
      <div
        class="all_message_box_item"
        @click="toMessageDetail(item)"
        v-for="(item, index) in messageList"
        :key="index"
      >
        <img :src="item.thumb" alt="" />
        <div class="right">
          <div class="right_top">
            <span class="title">{{ lang=='zh'? item.name:item.sp_name }}</span>
            <span class="time">{{ item.new_msg.create_time }}</span>
          </div>
          <div class="content">{{ lang=='zh'? item.new_msg.title:item.new_msg.title_span}}</div>
        </div>
      </div>
    </div>

    <!-- 详情 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="38%">
      <div slot="title">
        <div class="title_all">
          <div>{{ title }}</div>
        </div>
      </div>
      <div class="content">
        <div class="body">
          <div
            v-for="(obj, index) in newMessageList"
            :key="index"
            class="content_box"
            style=""
          >
            <img :src="obj.thumb" alt="" style="width: 40px; height: 40px" />
            <div>{{ lang=='zh'? obj.title:obj.title_span }}</div>
            <div>{{ obj.create_time }}</div>
            <button class="btn_check" @click="showInfo(obj)">
              {{ $t("view") }}
            </button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog title="" :visible.sync="showInfoStatus" width="38%">
      <div slot="title">
        <div class="title_all">
          <div v-if="lang == 'zh'">{{ showInfoContent.title }}</div>
          <div v-else>{{ showInfoContent.title_span }}</div>
        </div>
      </div>
      <div class="content">
        <div class="body">
          <div
            v-html="
              lang == 'zh'
                ? showInfoContent.contents
                : showInfoContent.contents_span
            "
           ></div>
          <img
            :src="showInfoContent.thumb"
            alt=""
            style="width: 180px; height: 180px"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messageList: [],
      newMessageList: [],
      dialogVisible: false,
      title: "",
      content: "",
      pageNo: 1,
      pageSize: 10000,

      lang: "",
      detail: "",
      dialogVisible: false,
      showInfoContent: {},
      showInfoStatus: false,
    };
  },
  mounted() {
    this.lang = localStorage.getItem("lang");

    this.getMsgType();
    this.getPlatformMsg();

    // 监听localstorage里面的数据
    window.addEventListener("setItemEvent", (e) => {
      if (e.key === "lang") {
        this.lang = e.newValue;
      }
    });
  },
  methods: {
    // 获取分类信息
    getMsgType() {
      this.$http.getMsgType().then((res) => {
        if (res.code == 1) {
          this.messageList = res.data;
        } else {
          this.$message.info(res.msg);
        }
      });
    },

    // 获取平台信息
    getPlatformMsg() {
      let data = {
        page: this.pageNo,
        pageSize: this.pageSize,
        msgtype: 1,
      };

      this.$http.getMsgList(data).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.newMessageList = res.data.data;
          console.log(this.newMessageList, 1234);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 获取平台信息
    getXitongformMsg() {
      let data = {
        page: this.pageNo,
        pageSize: this.pageSize,
        msgtype: 3,
      };

      this.$http.getMsgList(data).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.newMessageList = res.data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 去消息详情
    toMessageDetail(item) {
      console.log(item);
      if (item.msg_type == 0) {
        this.getPlatformMsg();
      } else if (item.msg_type == 1) {
        this.getXitongformMsg();
      }
      this.dialogVisible = true;
	  if(this.lang == 'zh'){
		this.title = item.name;

	  }else{
		this.title = item.sp_name
	  }
      this.content = item.new_msg.content;
      console.log(this.title);
      console.log(this.content);
      // if (item.msg_type == 1) {
      // 	this.$router.push('/myMessagePlatform')
      // } else {
      // 	this.$router.push('/myMessageSystem')
      // }
    },
    showInfo(obj) {
      console.log(obj);
      this.showInfoStatus = true;
      this.dialogVisible = false;
      this.showInfoContent = obj;
    },
  },
};
</script>

<style scoped="" lang="less">
.person_infor {
  width: 100%;
}

.login_top {
  display: flex;
  padding: 24px 40px;
  border-bottom: 1px solid #f3f5f6;

  .img {
    display: flex;
    align-items: center;

    img {
      width: 18px;
      height: 18px;
    }
  }

  .login_title {
    margin-left: 8px;
    font-weight: bold;
    font-size: 18px;
  }
}

.all_message_box {
  padding: 32px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .all_message_box_item {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 750px;
    height: 132px;
    background: rgba(255, 255, 255, 0.39);
    border: 1px solid #cccccc;
    opacity: 1;
    border-radius: 4px;
    margin: 0 0 40px 0;

    img {
      width: 96px;
      height: 96px;
      margin-left: 16px;
    }

    .right {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 24px;
      padding-right: 24px;

      .right_top {
        display: flex;
        justify-content: space-between;

        .title {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }

        .time {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }

      .content {
        text-align: left;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #666666;
      }
    }
  }
}

.all_message {
  padding: 0 40px;
  box-sizing: border-box;

  .message_item {
    padding-top: 32px;

    &:hover {
      cursor: pointer;
    }

    .message_item_top {
      display: flex;

      .message_item_left {
        img {
          width: 44px;
          height: 44px;
        }
      }

      .message_item_right {
        margin-left: 24px;

        .message_name {
          text-align: left;
          font-size: 16px;
          color: #333333;
        }

        .message_time {
          margin: 8px 0 16px;
          color: #bfbfbf;
          font-size: 14px;
        }
      }
    }

    .message_item_bottom {
      padding-bottom: 24px;
      border-bottom: 1px solid #eeeeee;
      margin-left: 68px;
      color: #666666;
      line-height: 24px;
      font-size: 14px;
      text-align: left;
      word-break: break-all;
    }
  }
}

.title_all {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  .title_img {
    margin-right: 8px;

    img {
      display: flex;
      align-items: center;
      width: 24px;
      height: 24px;
    }
  }

  .content {
    font-size: 14px;

  }
}

/deep/ .el-dialog__body {
  max-height: 436px;
  overflow-y: scroll;
  text-align: left;
  
}

/deep/.el-dialog__header {
  border-bottom: 1px solid #ddd !important;
}

/deep/ p{
		word-break: break-word !important;
	}

.content_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;

  .btn_check {
    width: 80px;
    height: 30px;
    color: #fff;
    border-radius: 6px;
    border: none;
    background-color: #ff8100;
  }
}

.content_box:last-child {
  border-bottom: none;
}
</style>
